<template>
  <div>
    <div class="Content"
      style="padding: 8px 10px; line-height: 30px;border-bottom: 1px solid rgb(246, 243, 243);">
      <div style="display: inline-block">
        <span style="font-size: 18px; color: #333">
          <van-icon name="manager" />{{ hzlist.Name }}
          <van-icon name="phone" />{{ hzlist.Mobile }}</span>
        <br />

        <span class="centertime"
          style="font-size: 18px; color: #333">
          报备时间：{{ hzlist.AddTime }}
        </span>
        <br />
        <span class="centertime"
          style="font-size: 18px; color: #333">
          核酸检测次数：{{ hzlist.NACheckCount }}
        </span>
      </div>
      <div style="width: 95%; margin: auto">
        <div style="width: 33%; display: inline-block; text-align: center"
          v-if="hzlist.HealthCode">
          <div style="margin-bottom: 5px">健康码</div>
          <img style="width: 80px; height: 80px"
            :src="hzlist.HealthCodeUrl"
            @click="openHealth(hzlist.HealthCodeUrl)" />
        </div>
        <div style="width: 33%; display: inline-block; text-align: center"
          v-if="hzlist.TravelCode">
          <div style="margin-bottom: 5px">行程码</div>
          <img style="width: 80px; height: 80px"
            :src="hzlist.TravelCodeUrl"
            @click="TravelHealth(hzlist.TravelCodeUrl)" />
        </div>
        <div style="width: 33%; display: inline-block; text-align: center"
          v-if="hzlist.Testify">
          <div style="margin-bottom: 5px">核酸证明</div>
          <img style="width: 80px; height: 80px"
            :src="hzlist.TestifyUrl"
            @click="TestifyHealth(hzlist.TestifyUrl)" />
        </div>
      </div>
    </div>
    <div style="margin-bottom: 60px">
      <div class="center"
        v-for="item in list"
        :key="item.index">
        <!-- <router-link :to="'/article/detail/' + item.RIId"> -->
        <div class="Content"
          style="padding: 8px 10px; line-height: 30px">
          <div style="display: inline-block">
            <span class="centertime"
              style="font-size: 18px; color: #333">
              检测结果：{{ item.CheckResultDesc }}
            </span>
            <br />
            <span class="centertime"
              style="font-size: 18px; color: #333">
              检测时间：{{ item.CheckTime }}
            </span>
          </div>
          <div style="display: inline-block; width: 95%; text-align: right"
            class="ContentB">
            <span class="centertime"
              style="color: #333">
              <van-button type="info"
                round
                style="width: 80px;font-size:13px;"
                @click="hsUpdata(item.NARId)"
                size="mini">编辑</van-button>
            </span>
          </div>
        </div>
        <!-- </router-link> -->
      </div>
    </div>

    <!-- <van-cell title="健康码"
        value="" /> -->
    <!-- <van-image width="100"
        height="100"
        :src="HealthCodeUrl" /> -->
    <!-- <van-uploader v-model="HealthCodeUrl" /> -->
    <div v-if="hidden"
      class="hidden">───── 没有更多数据了 ─────</div>
    <div style="">
      <div style="width: 100%; bottom: 0; position: fixed; padding: 10px">
        <van-button round
          type="info"
          color="#617bfa"
          size="normal"
          style="font-size: 16px; width: 95%"
          @click="SaveZh()">新增</van-button>
      </div>
    </div>
  </div>
</template>
<script>
import { WxGetReporting, WxGetNACheckPage } from "@/api/fangyi";
import { getwgToken } from "@/utils/auth";
import { ImagePreview } from "vant";
export default {
  name: "ImagePreview ",
  data () {
    return {
      listfrom: {},
      hzlist: [],
      url: "https://api.qxzhqm.cn/",
      HealthCodeUrl: [],
      list: {},
      hidden: false,
    };
  },
  created () {
    this.cyXq();
  },
  methods: {
    // 健康码
    openHealth: function (row) {
      console.log(row);
      ImagePreview({
        // images:this.newImgUrl,
        images: [row],
        startPosition: 0,
        closeOnPopstate: true,
      });
    },
    // 行程码
    TravelHealth: function (row) {
      console.log(row);
      ImagePreview({
        // images:this.newImgUrl,
        images: [row],
        startPosition: 0,
        closeOnPopstate: true,
      });
    },
    // 核酸证明
    TestifyHealth: function (row) {
      console.log(row);
      ImagePreview({
        // images:this.newImgUrl,
        images: [row],
        startPosition: 0,
        closeOnPopstate: true,
      });
    },
    // 获取住户列表
    cyXq () {
      console.log(this.$route);
      // console.log(JSON.parse(this.$route.params.itemmark));
      // this.HealthCodeUrl = this.url + this.hzlist.HealthCode
      // console.log(this.HealthCodeUrl);
      WxGetReporting({ id: this.$route.params.id })
        .then((res) => {
          if (res.data.code == 0) {
            this.hzlist = res.data.data;
            console.log(this.hzlist.ID);
            this.heSuanJu(this.hzlist.ID);
          }
        })
        .catch(() => { });
    },
    heSuanJu: function (irId) {
      WxGetNACheckPage({ irId: irId })
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.count == 0) {
              this.hidden = true;
              this.list = {};
            } else {
              this.list = res.data.data;
              this.total = res.data.count;
            }
          }
        })
        .catch(() => { });
    },
    SaveZh: function () {
      console.log(this.$route.params.id);
      this.$router.push({
        path:
          "/register/fangyi/myFangKong/fkFanQi/fanQiHs/fqHeSuanAdd/" +
          this.$route.params.id +
          "/" +
          0,
      });
    },
    hsUpdata: function (row) {
      console.log(row);
      this.$router.push({
        path:
          "/register/fangyi/myFangKong/fkFanQi/fanQiHs/fqHeSuanAdd/" +
          this.$route.params.id +
          "/" +
          row,
      });
    },
  },
};
</script>
<style scoped>
.van-cell {
  font-size: 15px !important;
}
.rightClass {
  width: 60% !important;
}
.shopTitle {
  color: #333;
  padding: 10px;
}
.information .center {
  border-bottom: none;
  padding: 0px;
  border-radius: 5px;
}
.center {
  width: 92%;
  margin: 10px auto;
  background: rgb(246, 243, 243);
  /* border: 1px solid rgb(246, 243, 243); */
  border-radius: 10px;
}
.hidden {
  text-align: center;
  font-size: 12px;
  color: #ccc;
  margin: 10px 0px 25px 0px;
}
</style>